import Popover from "common/components/Popover/Popover";
import "./styles.scss";
import { useTranslation } from "react-i18next";

type Props = { text: string; count: number };

function CustomButtonWithPopover({ text, count }: Props) {
  const [t] = useTranslation("common");

  const redirectClickHandler = () => {
    window.location.href = t("communityLink");
  };

  return (
    <div className="buttonWrrapper">
      <button type="button" className="button" onClick={redirectClickHandler} >
        {text} 
      </button>
      <Popover count={count} />
    </div>
  );
}

export default CustomButtonWithPopover;
