import { CardUser } from "common/components/Cards/props";
import "./styles.scss";

type Props = {
  previewImg: string;
  title: string;
  text: string;
  link: string;
  users: CardUser[];
};

function CardProject({ previewImg, title, text, link, users }: Props) {
  return (
    <div className="cardProject">
      <img className="cardProject__previewImg" src={previewImg} alt="preview" />
      <div className="cardProject__content">
        <h3 className="subtitle">
          <a href={link} target="_blank">
            {title}
          </a>
        </h3>
        <p className="text">{text}</p>
      </div>
      <div className="cardProject__footer">
        {users.map((it) => (
          <div className="cardProject__footerItem">
            <img src={it.avatar} alt="user avatar" />
            <div className="cardProject__userInfo">
              <h4>
                <a href={it.link} target="_blank">
                  {it.name}
                </a>
              </h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardProject;
