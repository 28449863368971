import { useTranslation } from "react-i18next";
// import { NavLink } from "react-router-dom";
import { ReactComponent as UsersIcon } from "common/assets/icons/sliderIcons/users.svg";
import { ReactComponent as SearchIcon } from "common/assets/icons/sliderIcons/search.svg";

import { ReactComponent as LocationIcon } from "common/assets/icons/sliderIcons/location.svg";

import { ReactComponent as StarIcon } from "common/assets/icons/sliderIcons/star.svg";

import { ReactComponent as SettingsIcon } from "common/assets/icons/sliderIcons/settings.svg";

import CustomButton from "common/components/Buttons/CustomButton/CustomButton";
import SliderDescriptionItem from "./SliderDescriptionItem";

import "./styles.scss";

type Props = {
  name: string;
  // img: string;
  usersCount: number;
  mapCount: number;
  url: string;
};

function SliderCard({ name, usersCount, mapCount, url }: Props) {
  const [t] = useTranslation("common");
  const redirectClickHandler = () => {
    window.location.href = url;
  };
  const elements = [
    {
      icon: UsersIcon,
      title: `${usersCount} ${t("sliderCard.participant")}`,
      descr: t("sliderCard.group"),
    },
    {
      icon: SearchIcon,
      title: t("sliderCard.open"),
      descr: t("sliderCard.vacancies"),
      link: "https://innovaforge.zohorecruit.eu/jobs/Careers",
      // link: `vacancies?group=${name.toUpperCase()}`,
    },
    {
      icon: LocationIcon,
      title: t("sliderCard.mapTitle"),
      descr: `${mapCount}  ${t("sliderCard.mapDescr")}`,
      link: "map",
    },
    {
      icon: StarIcon,
      title: t("sliderCard.meetupTitle"),
      descr: t("sliderCard.meetupDescr"),
      link: "https://www.eventbrite.com/e/network-itio-meet-up-tickets-697599477817?aff=ebdsoporgprofile",
      // link: `meetups?group=${name.toUpperCase()}`,
    },
    {
      icon: SettingsIcon,
      title: t("sliderCard.jointTitle"),
      descr: t("sliderCard.jointDescr"),
      link: "common-projects",
    },
  ];

  return (
    <div className="sliderCard">
      <div className="sliderCard__openedCardTitleWrapper">
        <h3 className="sliderCard__openedCardTitle">{name.toUpperCase()}</h3>
      </div>

      <div className="sliderCard__contentWrapper">
        {elements.map(({ title, icon, descr, link }) => (
          <div className="sliderCard__elementWrapper" key={title}>
            <a
              className="sliderCard__elementLink"
              href={link !== undefined ? link : url}
            >
              <SliderDescriptionItem title={title} Icon={icon} descr={descr} />
            </a>
          </div>
        ))}
      </div>

      <CustomButton
        text={t("sliderCard.buttonText")}
        onClick={redirectClickHandler}
      />
    </div>
  );
}

export default SliderCard;
