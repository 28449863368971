import Slider from "react-slick";

import RuTelegramChatImg from "common/assets/images/slider_images/ru/telegramChat.png";
import RuTelegramChatImg1 from "common/assets/images/slider_images/ru/telegramChat1.png";
import RuTelegramChatImg2 from "common/assets/images/slider_images/ru/telegramChat2.png";
import RuTelegramChatImg3 from "common/assets/images/slider_images/ru/telegramChat3.png";
import RuTelegramChatImg4 from "common/assets/images/slider_images/ru/telegramChat4.png";
import RuTelegramChatImg5 from "common/assets/images/slider_images/ru/telegramChat5.png";
import RuTelegramChatImg6 from "common/assets/images/slider_images/ru/telegramChat6.png";
import RuTelegramChatImg7 from "common/assets/images/slider_images/ru/telegramChat7.png";
import RuTelegramChatImg8 from "common/assets/images/slider_images/ru/telegramChat8.png";
import RuTelegramChatImg9 from "common/assets/images/slider_images/ru/telegramChat9.png";
import RuTelegramChatImg10 from "common/assets/images/slider_images/ru/telegramChat10.png";
import RuTelegramChatImg11 from "common/assets/images/slider_images/ru/telegramChat11.png";
import RuTelegramChatImg12 from "common/assets/images/slider_images/ru/telegramChat12.png";
import RuTelegramChatImg13 from "common/assets/images/slider_images/ru/telegramChat13.png";
import RuTelegramChatImg14 from "common/assets/images/slider_images/ru/telegramChat14.png";

// import DeTelegramChatImg from "common/assets/images/slider_images/de/telegramChat.png";
// import DeTelegramChatImg1 from "common/assets/images/slider_images/de/telegramChat1.png";

import PhoneRectangle from "common/assets/images/phoneBackground.png";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./styles.scss";
import { useRef } from "react";
import ArrowButton from "common/components/Buttons/ArrowButton/ArrowButton";
// import getCurrentLang from "utils/getCurrentLang";

function Members() {
  const [t] = useTranslation("common");
  const ref = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoPlaySpeed: 5000,
  };

  const click = (side: "next" | "prev") => {
    if (side === "prev") {
      (ref?.current as any)?.slickPrev();
    }
    if (side === "next") {
      (ref?.current as any)?.slickNext();
    }
  };

  // const sliderImages = getCurrentLang() === 'ru'
  // ? [RuTelegramChatImg, RuTelegramChatImg1, RuTelegramChatImg2, RuTelegramChatImg3, RuTelegramChatImg4, RuTelegramChatImg5, RuTelegramChatImg6, RuTelegramChatImg7, RuTelegramChatImg8, RuTelegramChatImg9, RuTelegramChatImg10, RuTelegramChatImg11, RuTelegramChatImg12, RuTelegramChatImg13, RuTelegramChatImg14]
  // : [DeTelegramChatImg, DeTelegramChatImg1]
  const sliderImages = [
    RuTelegramChatImg,
    RuTelegramChatImg1,
    RuTelegramChatImg2,
    RuTelegramChatImg3,
    RuTelegramChatImg4,
    RuTelegramChatImg5,
    RuTelegramChatImg6,
    RuTelegramChatImg7,
    RuTelegramChatImg8,
    RuTelegramChatImg9,
    RuTelegramChatImg10,
    RuTelegramChatImg11,
    RuTelegramChatImg12,
    RuTelegramChatImg13,
    RuTelegramChatImg14,
  ];

  return (
    <div className="container">
      <div className="members ">
        <div className="members__sliderWrapper">
          <img
            className="members__phoneRect"
            src={PhoneRectangle}
            alt="phone rect"
          />
          <Slider {...settings} ref={ref}>
            {sliderImages.map((it) => (
              <div key={Math.random()}>
                <img className="members__screenshot" src={it} alt={it} />
              </div>
            ))}
          </Slider>
          <div className="members__buttonsWrapperMobile">
            <ArrowButton side="prev" onClick={() => click("prev")} />
            <ArrowButton side="next" onClick={() => click("next")} />
          </div>
        </div>
        <div className="members__rightContent">
          <h1 className="title">{t("communities.membersTitle")}</h1>
          <p className="text">{t("communities.membersText")}</p>
          <div className="members__buttonsWrapper">
            <ArrowButton side="prev" onClick={() => click("prev")} />
            <ArrowButton side="next" onClick={() => click("next")} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Members;
