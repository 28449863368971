import RectangeleMobile from "common/assets/images/rectangle-mobile-impressum.png";
import RectangeleImpressum from "common/assets/images/impressum/rectangle-impressum.png";
import { useTranslation } from "react-i18next";

import "./styles.scss";

function Impressum() {
  const [t] = useTranslation("common");
  return (
    <div className="impressum">
      <img
        className="impressum__rectangle"
        src={RectangeleImpressum}
        alt="rectangle - impressum"
      />
      <img
        className="impressum__rectangle-mobile"
        src={RectangeleMobile}
        alt="rectangle mobile"
      />
      <div className="container">
        <div className="impressum__contentWrapper">
          <div className="impressum__leftContent">
            <h1 className="title">{t("impressum.title")}</h1>
            <br />
            <h3 className="title-gradient subtitle">
              {t("impressum.adressTitle")}
            </h3>
            <p className="impressum__adressText">{t("impressum.adressText")}</p>
            <div className="impressum__name">
              <h3 className="title-gradient subtitle">
                {t("impressum.subtitle")}
              </h3>
              <p className="text">{t("impressum.name")}</p>
            </div>
            <div className="impressum__kontact">
              <h3 className="title-gradient subtitle">
                {t("impressum.contact")}
              </h3>
              <p className="text">{t("impressum.telefon")} 069 3487 2074</p>
              <p className="text">E-Mail: info@innovaforge.de</p>
            </div>
          </div>
          <div className="impressum__rightContent">
            <div className="impressum__textBlock">
              <h3 className="title-gradient subtitle">
                Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz
              </h3>
              <p className="text">DE360075860</p>
            </div>

            <div className="impressum__textBlock">
              <h3 className="title-gradient subtitle">Steuernummer</h3>
              <p className="text">4523627947</p>
            </div>

            <div className="impressum__textBlock">
              <h3 className="title-gradient subtitle">
                Handelsregistereintrag
              </h3>
              <p className="text">Amtsgericht Frankfurt am Main HRB 130565</p>
            </div>

            <div className="impressum__textBlock">
              <h3 className="title-gradient subtitle">Haftung für Inhalte</h3>
              <p className="text">
                Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
                Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
                können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter
                sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
                Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
                bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                verpflichtet, übermittelte oder gespeicherte fremde
                Informationen zu überwachen oder nach Umständen zu forschen, die
                auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                Entfernung oder Sperrung der Nutzung von Informationen nach den
                allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
                diese Inhalte umgehend entfernen.
              </p>
            </div>

            <div className="impressum__textBlock">
              <h3 className="title-gradient subtitle">Haftung für Links</h3>
              <p className="text">
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf
                deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
                diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                Verlinkung nicht erkennbar. Eine permanente inhaltliche
                Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend entfernen.
              </p>
            </div>

            <div className="impressum__textBlock">
              <h3 className="title-gradient subtitle">Urheberrecht</h3>
              <p className="text">
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten,
                nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                dieser Seite nicht vom Betreiber erstellt wurden, werden die
                Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
                Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                werden wir derartige Inhalte umgehend entfernen.
              </p>
            </div>

            <div className="impressum__textBlock">
              <h3 className="title-gradient subtitle">Datenschutz</h3>
              <p className="text">
                Die Nutzung unserer Webseite ist in der Regel ohne Angabe
                personenbezogener Daten möglich. Soweit auf unseren Seiten
                personenbezogene Daten (beispielsweise Name, Anschrift oder
                eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
                stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
                ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir
                weisen darauf hin, dass die Datenübertragung im Internet (z.B.
                bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
                kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
                Dritte ist nicht möglich. Der Nutzung von im Rahmen der
                Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur
                Übersendung von nicht ausdrücklich angeforderter Werbung und
                Informationsmaterialien wird hiermit ausdrücklich widersprochen.
                Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
                Schritte im Falle der unverlangten Zusendung von
                Werbeinformationen, etwa durch Spam-Mails, vor.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Impressum;
