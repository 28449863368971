import Rectangle from "common/assets/images/contacts/Rectangle.png";
import RectangleMobile from "common/assets/images/rectangle-mobile-contacts.png";
import EdgarAvatar from "common/assets/images/contacts/edgar.jpg";
import BogdanAvatar from "common/assets/images/contacts/bogdan.jpg";
import CardContacts from "common/components/Cards/CardContacts";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const contacts = [
  {
    avatarImg: BogdanAvatar,
    title: "Bogdan Lashkov",
    text: "Founder",
    instagramUrl: "https://www.instagram.com/60gdan/",
    telegramUrl: "https://t.me/b09dan",
    linkedinUrl: "https://www.linkedin.com/in/bogdan-lashkov/"
  },
  {
    avatarImg: EdgarAvatar,
    title: "Edgar Linde",
    text: "Founder",
    instagramUrl: "https://www.instagram.com/network_it.io/",
    telegramUrl: "https://t.me/edgarlinde",
    linkedinUrl: "https://www.linkedin.com/in/edgar-linde-a55775177/"
  }
];
function Contacts() {
  const [t] = useTranslation("common");
  return (
    <div className="contacts">
      <img className="rectangle" src={Rectangle} alt="rectangle" />
      <img
        className="rectangle-mobile"
        src={RectangleMobile}
        alt="rectangle mobile"
      />

      <div className="container">
        <h1 className="title"> {t("contacts.title")}</h1>
        <div className="contacts__cardsWrapper">
          {contacts.map(({ avatarImg, title, text, instagramUrl, telegramUrl, linkedinUrl }) => (
            <CardContacts
              key={title}
              avatarImg={avatarImg}
              title={title}
              text={text}
              instagramUrl={instagramUrl}
              telegramUrl={telegramUrl}
              linkedinUrl={linkedinUrl}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Contacts;
