import RectangeleMobile from "common/assets/images/rectangle-mobile-impressum.png";
import RectangeleImpressum from "common/assets/images/impressum/rectangle-impressum.png";
import { useTranslation } from "react-i18next";

import "./styles.scss";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  const [t] = useTranslation("common");
  return (
    <div className="privacy-policy">
      {/* <img
        className="privacy-policy__rectangle"
        src={RectangeleImpressum}
        alt="rectangle - impressum"
      /> */}
      {/* <img
        className="privacy-policy__rectangle-mobile"
        src={RectangeleMobile}
        alt="rectangle mobile"
      /> */}
      <div className="container">
        <div className="privacy-policy__contentWrapper">
          <div className="privacy-policy__contact-info">
            <h1 className="title">{t("privacyPolicy.title")}</h1>
            <br />
            <h3 className="title-gradient subtitle">
              {t("privacyPolicy.contactTitle")}
            </h3>
            <p className="privacy-policy__adressText">
              {t("impressum.adressText")}
            </p>

            <p className="privacy-policy__link">
              E-Mail-Adresse:{" "}
              <a
                className="privacyPolicy__link title-gradient"
                href="mailto:info@innovaforge.de"
              >
                info@innovaforge.de
              </a>
            </p>
            <p className="privacy-policy__link">
              Impressum:{" "}
              <Link to="/impressum" className="title-gradient">
                Impressum
              </Link>
            </p>
          </div>
          <div className="privacy-policy__content">
            <div className="privacy-policy__textBlock">
              <h3 className="title-gradient subtitle ">
                Übersicht der Verarbeitungen
              </h3>
              <p className="text">
                Die nachfolgende Übersicht fasst die Arten der verarbeiteten
                Daten und die Zwecke ihrer Verarbeitung zusammen und verweist
                auf die betroffenen Personen.
              </p>
            </div>

            <div className="privacy-policy__textBlock">
              <h3 className="title-gradient subtitle ">
                Arten der verarbeiteten Daten
              </h3>
              <p className="text">
                <ul className="privacy-policy__list">
                  <li>Kontaktdaten.</li>
                  <li>Inhaltsdaten.</li>
                  <li>Nutzungsdaten.</li>
                  <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
                </ul>
              </p>
            </div>
            <div className="privacy-policy__textBlock">
              <h3 className="title-gradient subtitle ">
                Kategorien betroffener Personen
              </h3>
              <p className="text">
                <ul className="privacy-policy__list">
                  <li>Kommunikationspartner.</li>
                  <li>Nutzer.</li>
                </ul>
              </p>
            </div>
            <div className="privacy-policy__textBlock">
              <h3 className="title-gradient subtitle ">
                Zwecke der Verarbeitung
              </h3>
              <p className="text">
                <ul className="privacy-policy__list">
                  <li>Kontaktanfragen und Kommunikation.</li>
                  <li>Verwaltung und Beantwortung von Anfragen.</li>
                  <li>Feedback.</li>
                  <li>Marketing.</li>
                  <li>
                    Bereitstellung unseres Onlineangebotes und
                    Nutzerfreundlichkeit.
                  </li>
                </ul>
              </p>
            </div>
            <div className="privacy-policy__textBlock">
              <h3 className="title-gradient subtitle ">
                Zwecke der Verarbeitung
              </h3>
              <p className="text">
                <span className="fw-bold">
                  Maßgebliche Rechtsgrundlagen nach der DSGVO:
                </span>{" "}
                Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen
                der DSGVO, auf deren Basis wir personenbezogene Daten
                verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den
                Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem bzw.
                unserem Wohn- oder Sitzland gelten können. Sollten ferner im
                Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen
                wir Ihnen diese in der Datenschutzerklärung mit.
                <ul className="privacy-policy__list privacy-policy__list_nested pt-20">
                  <li>
                    <span className="fw-bold">
                      Vertragserfüllung und vorvertragliche Anfragen (Art. 6
                      Abs. 1 S. 1 lit. b) DSGVO)
                    </span>{" "}
                    - Die Verarbeitung ist für die Erfüllung eines Vertrags,
                    dessen Vertragspartei die betroffene Person ist, oder zur
                    Durchführung vorvertraglicher Maßnahmen erforderlich, die
                    auf Anfrage der betroffenen Person erfolgen.
                  </li>
                  <li>
                    <span className="fw-bold">
                      Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
                    </span>{" "}
                    - Die Verarbeitung ist zur Wahrung der berechtigten
                    Interessen des Verantwortlichen oder eines Dritten
                    erforderlich, sofern nicht die Interessen oder Grundrechte
                    und Grundfreiheiten der betroffenen Person, die den Schutz
                    personenbezogener Daten erfordern, überwiegen.
                  </li>
                </ul>
              </p>

              <p className="text pt-20">
                <span className="fw-bold">
                  Nationale Datenschutzregelungen in Deutschland:{" "}
                </span>
                Zusätzlich zu den Datenschutzregelungen der DSGVO gelten
                nationale Regelungen zum Datenschutz in Deutschland. Hierzu
                gehört insbesondere das Gesetz zum Schutz vor Missbrauch
                personenbezogener Daten bei der Datenverarbeitung
                (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere
                Spezialregelungen zum Recht auf Auskunft, zum Recht auf
                Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer
                Kategorien personenbezogener Daten, zur Verarbeitung für andere
                Zwecke und zur Übermittlung sowie automatisierten
                Entscheidungsfindung im Einzelfall einschließlich Profiling.
                Ferner können Landesdatenschutzgesetze der einzelnen
                Bundesländer zur Anwendung gelangen.
              </p>
            </div>

            <div className="privacy-policy__textBlock">
              <h3 className="title-gradient subtitle ">
                Sicherheits­maß­nahmen
              </h3>
              <p className="text">
                Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
                Berücksichtigung des Stands der Technik, der
                Implementierungskosten und der Art, des Umfangs, der Umstände
                und der Zwecke der Verarbeitung sowie der unterschiedlichen
                Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der
                Rechte und Freiheiten natürlicher Personen geeignete technische
                und organisatorische Maßnahmen, um ein dem Risiko angemessenes
                Schutzniveau zu gewährleisten.
              </p>
              <p className="text pt-20">
                Zu den Maßnahmen gehören insbesondere die Sicherung der
                Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
                Kontrolle des physischen und elektronischen Zugangs zu den Daten
                als auch des sie betreffenden Zugriffs, der Eingabe, der
                Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung.
                Des Weiteren haben wir Verfahren eingerichtet, die eine
                Wahrnehmung von Betroffenenrechten, die Löschung von Daten und
                Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner
                berücksichtigen wir den Schutz personenbezogener Daten bereits
                bei der Entwicklung bzw. Auswahl von Hardware, Software sowie
                Verfahren entsprechend dem Prinzip des Datenschutzes, durch
                Technikgestaltung und durch datenschutzfreundliche
                Voreinstellungen.
              </p>
              <p className="text pt-20">
                TLS/SSL-Verschlüsselung (https): Um die Daten der Benutzer, die
                über unsere Online-Dienste übertragen werden, zu schützen,
                verwenden wir TLS/SSL-Verschlüsselung. Secure Sockets Layer
                (SSL) ist die Standardtechnologie zur Sicherung von
                Internetverbindungen durch Verschlüsselung der zwischen einer
                Website oder App und einem Browser (oder zwischen zwei Servern)
                übertragenen Daten. Transport Layer Security (TLS) ist eine
                aktualisierte und sicherere Version von SSL. Hyper Text Transfer
                Protocol Secure (HTTPS) wird in der URL angezeigt, wenn eine
                Website durch ein SSL/TLS-Zertifikat gesichert ist.
              </p>
            </div>
            <div className="privacy-policy__textBlock">
              <h3 className="title-gradient subtitle ">Löschung von Daten</h3>
              <p className="text">
                Die von uns verarbeiteten Daten werden nach Maßgabe der
                gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung
                erlaubten Einwilligungen widerrufen werden oder sonstige
                Erlaubnisse entfallen (z. B. wenn der Zweck der Verarbeitung
                dieser Daten entfallen ist oder sie für den Zweck nicht
                erforderlich sind). Sofern die Daten nicht gelöscht werden, weil
                sie für andere und gesetzlich zulässige Zwecke erforderlich
                sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D.
                h., die Daten werden gesperrt und nicht für andere Zwecke
                verarbeitet. Das gilt z. B. für Daten, die aus handels- oder
                steuerrechtlichen Gründen aufbewahrt werden müssen oder deren
                Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
                Rechtsansprüchen oder zum Schutz der Rechte einer anderen
                natürlichen oder juristischen Person erforderlich ist. Unsere
                Datenschutzhinweise können ferner weitere Angaben zu der
                Aufbewahrung und Löschung von Daten beinhalten, die für die
                jeweiligen Verarbeitungen vorrangig gelten.
              </p>
              instellungen.
            </div>
            <div className="privacy-policy__textBlock">
              <h3 className="title-gradient subtitle ">
                Rechte der betroffenen Personen
              </h3>
              <p className="text">
                Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als
                Betroffene nach der DSGVO verschiedene Rechte zu, die sich
                insbesondere aus Art. 15 bis 21 DSGVO ergeben:
                <ul className="privacy-policy__list privacy-policy__list_nested pt-20">
                  <li>
                    <span className="fw-bold">Widerspruchsrecht:</span> Sie
                    haben das Recht, aus Gründen, die sich aus Ihrer besonderen
                    Situation ergeben, jederzeit gegen die Verarbeitung der Sie
                    betreffenden personenbezogenen Daten, die aufgrund von Art.
                    6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch
                    einzulegen; dies gilt auch für ein auf diese Bestimmungen
                    gestütztes Profiling. Werden die Sie betreffenden
                    personenbezogenen Daten verarbeitet, um Direktwerbung zu
                    betreiben, haben Sie das Recht, jederzeit Widerspruch gegen
                    die Verarbeitung der Sie betreffenden personenbezogenen
                    Daten zum Zwecke derartiger Werbung einzulegen; dies gilt
                    auch für das Profiling, soweit es mit solcher Direktwerbung
                    in Verbindung steht.
                  </li>
                  <li>
                    <span className="fw-bold">
                      Widerrufsrecht bei Einwilligungen:
                    </span>{" "}
                    Sie haben das Recht, erteilte Einwilligungen jederzeit zu
                    widerrufen.
                  </li>
                  <li>
                    <span className="fw-bold">Auskunftsrecht:</span> Sie haben
                    das Recht, eine Bestätigung darüber zu verlangen, ob
                    betreffende Daten verarbeitet werden und auf Auskunft über
                    diese Daten sowie auf weitere Informationen und Kopie der
                    Daten entsprechend den gesetzlichen Vorgaben.
                  </li>
                  <li>
                    <span className="fw-bold">Recht auf Berichtigung:</span> Sie
                    haben entsprechend den gesetzlichen Vorgaben das Recht, die
                    Vervollständigung der Sie betreffenden Daten oder die
                    Berichtigung der Sie betreffenden unrichtigen Daten zu
                    verlangen.
                  </li>
                  <li>
                    <span className="fw-bold">
                      Recht auf Löschung und Einschränkung der Verarbeitung:
                    </span>{" "}
                    Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht,
                    zu verlangen, dass Sie betreffende Daten unverzüglich
                    gelöscht werden, bzw. alternativ nach Maßgabe der
                    gesetzlichen Vorgaben eine Einschränkung der Verarbeitung
                    der Daten zu verlangen.
                  </li>
                  <li>
                    <span className="fw-bold">
                      Recht auf Datenübertragbarkeit:
                    </span>{" "}
                    Sie haben das Recht, Sie betreffende Daten, die Sie uns
                    bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben
                    in einem strukturierten, gängigen und maschinenlesbaren
                    Format zu erhalten oder deren Übermittlung an einen anderen
                    Verantwortlichen zu fordern.
                  </li>
                  <li>
                    <span className="fw-bold">
                      Beschwerde bei Aufsichtsbehörde:{" "}
                    </span>{" "}
                    Sie haben unbeschadet eines anderweitigen
                    verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das
                    Recht auf Beschwerde bei einer Aufsichtsbehörde,
                    insbesondere in dem Mitgliedstaat ihres gewöhnlichen
                    Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
                    mutmaßlichen Verstoßes, wenn Sie überzeugt sind, dass die
                    Verarbeitung der Sie betreffenden personenbezogenen Daten
                    gegen die Vorgaben der DSGVO verstößt.
                  </li>
                </ul>
              </p>
            </div>
            <div className="privacy-policy__textBlock">
              <h3 className="title-gradient subtitle ">
                Kontakt- und Anfragen­verwaltung
              </h3>
              <p className="text">
                Bei der Kontaktaufnahme mit uns (z. B. per Post,
                Kontaktformular, E-Mail, Telefon oder via soziale Medien) sowie
                im Rahmen bestehender Nutzer- und Geschäftsbeziehungen werden
                die Angaben der anfragenden Personen verarbeitet, soweit dies
                zur Beantwortung der Kontaktanfragen und etwaiger angefragter
                Maßnahmen erforderlich ist.
                <ul className="privacy-policy__list privacy-policy__list_nested pt-20">
                  <li>
                    <span className="fw-bold">Verarbeitete Datenarten:</span>{" "}
                    Kontaktdaten (z. B. E-Mail, Telefonnummern); Inhaltsdaten
                    (z. B. Eingaben in Onlineformularen); Nutzungsdaten (z. B.
                    besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
                    Meta-, Kommunikations- und Verfahrensdaten (z. .B.
                    IP-Adressen, Zeitangaben, Identifikationsnummern,
                    Einwilligungsstatus).
                  </li>
                  <li>
                    <span className="fw-bold">Betroffene Personen:</span>{" "}
                    Kommunikationspartner.
                  </li>
                  <li>
                    <span className="fw-bold">Zwecke der Verarbeitung:</span>{" "}
                    Kontaktanfragen und Kommunikation; Verwaltung und
                    Beantwortung von Anfragen; Feedback (z. B. Sammeln von
                    Feedback via Online-Formular). Bereitstellung unseres
                    Onlineangebotes und Nutzerfreundlichkeit.
                  </li>
                  <li>
                    <span className="fw-bold">Rechtsgrundlagen: </span>{" "}
                    Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                    Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs.
                    1 S. 1 lit. b) DSGVO).
                  </li>
                </ul>
                <p className="text pt-20 fw-bold">
                  Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
                  Diensten:
                </p>
                <ul className="privacy-policy__list privacy-policy__list_nested pt-20">
                  <li>
                    <span className="fw-bold">Kontaktformular:</span> Wenn
                    Nutzer über unser Kontaktformular, E-Mail oder andere
                    Kommunikationswege mit uns in Kontakt treten, verarbeiten
                    wir die uns in diesem Zusammenhang mitgeteilten Daten zur
                    Bearbeitung des mitgeteilten Anliegens;{" "}
                    <span className="fw-bold">Rechtsgrundlagen:</span>{" "}
                    Rechtsgrundlagen: Vertragserfüllung und vorvertragliche
                    Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO), Berechtigte
                    Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                  </li>
                </ul>
              </p>
            </div>
            <div className="privacy-policy__textBlock">
              <h3 className="title-gradient subtitle ">
                Präsenzen in sozialen Netzwerken (Social Media)
              </h3>
              <p className="text">
                Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
                verarbeiten in diesem Rahmen Daten der Nutzer, um mit den dort
                aktiven Nutzern zu kommunizieren oder um Informationen über uns
                anzubieten.
              </p>
              <p className="text pt-20">
                Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des
                Raumes der Europäischen Union verarbeitet werden können.
                Hierdurch können sich für die Nutzer Risiken ergeben, weil so z.
                B. die Durchsetzung der Rechte der Nutzer erschwert werden
                könnte.
              </p>
              <p className="text pt-20">
                Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke
                im Regelfall für Marktforschungs- und Werbezwecke verarbeitet.
                So können z. B. anhand des Nutzungsverhaltens und sich daraus
                ergebender Interessen der Nutzer Nutzungsprofile erstellt
                werden. Die Nutzungsprofile können wiederum verwendet werden, um
                z. B. Werbeanzeigen innerhalb und außerhalb der Netzwerke zu
                schalten, die mutmaßlich den Interessen der Nutzer entsprechen.
                Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern
                der Nutzer gespeichert, in denen das Nutzungsverhalten und die
                Interessen der Nutzer gespeichert werden. Ferner können in den
                Nutzungsprofilen auch Daten unabhängig der von den Nutzern
                verwendeten Geräte gespeichert werden (insbesondere, wenn die
                Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen
                eingeloggt sind).
              </p>
              <p className="text pt-20">
                Für eine detaillierte Darstellung der jeweiligen
                Verarbeitungsformen und der Widerspruchsmöglichkeiten (Opt-Out)
                verweisen wir auf die Datenschutzerklärungen und Angaben der
                Betreiber der jeweiligen Netzwerke.
              </p>
              <p className="text pt-20">
                Auch im Fall von Auskunftsanfragen und der Geltendmachung von
                Betroffenenrechten weisen wir darauf hin, dass diese am
                effektivsten bei den Anbietern geltend gemacht werden können.
                Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer
                und können direkt entsprechende Maßnahmen ergreifen und
                Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann
                können Sie sich an uns wenden.
              </p>

              <ul className="privacy-policy__list privacy-policy__list_nested pt-20 text">
                <li>
                  <span className="fw-bold">Verarbeitete Datenarten: </span>{" "}
                  Kontaktdaten (z. B. E-Mail, Telefonnummern); Inhaltsdaten (z.
                  B. Eingaben in Onlineformularen); Nutzungsdaten (z. B.
                  besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
                  Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen,
                  Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
                </li>
                <li>
                  <span className="fw-bold">Betroffene Personen: </span> Nutzer
                  (z. B. Webseitenbesucher, Nutzer von Onlinediensten).
                </li>
                <li>
                  <span className="fw-bold">Verarbeitete Datenarten: </span>{" "}
                  Kontaktdaten (z. B. E-Mail, Telefonnummern); Inhaltsdaten (z.
                  B. Eingaben in Onlineformularen); Nutzungsdaten (z. B.
                  besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
                  Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen,
                  Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
                </li>
                <li>
                  <span className="fw-bold">Zwecke der Verarbeitung:</span>{" "}
                  Kontaktanfragen und Kommunikation; Feedback (z. B. Sammeln von
                  Feedback via Online-Formular). Marketing
                </li>
                <li>
                  <span className="fw-bold">Rechtsgrundlagen: </span>{" "}
                  Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                </li>
              </ul>

              <p className="text pt-20 fw-bold">
                Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
                Diensten:
              </p>

              <ul className="privacy-policy__list privacy-policy__list_nested pt-20 text">
                <li>
                  <span className="fw-bold">Instagram: </span> Soziales
                  Netzwerk; <span className="fw-bold">Dienstanbieter:</span>{" "}
                  Meta Platforms Ireland Limited, Merrion Road, Dublin 4, D04
                  X2K5, Irland;{" "}
                  <span className="fw-bold">Rechtsgrundlagen: </span>
                  Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);{" "}
                  <a className="link" href="https://www.instagram.com/">
                    Website
                  </a>
                  ;{" "}
                  <a
                    className="link"
                    href="https://privacycenter.instagram.com/policy/?entry_point=ig_help_center_data_policy_redirect"
                  >
                    Datenschutzerklärung.
                  </a>
                </li>
                <li>
                  <span className="fw-bold">Linkedin: </span>
                  Soziales Netzwerk;{" "}
                </li>
              </ul>
              <p className="text">
                <span className="fw-bold">Dienstanbieter: </span>LinkedIn
                Ireland Unlimited Company, Wilton Place, Dublin 2, Irland;
              </p>
              <p className="text">
                <span className="fw-bold">Rechtsgrundlagen: </span>Berechtigte
                Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);{" "}
                <a className="link" href="https://www.linkedin.com/">
                  Website
                </a>
                ;{" "}
                <a
                  className="link"
                  href="https://www.linkedin.com/legal/privacy-policy"
                >
                  Datenschutzerklärung
                </a>
                ;{" "}
                <a
                  className="link"
                  href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                >
                  Widerspruchsmöglichkeit
                </a>
                ;{" "}
                <a className="link" href="https://www.linkedin.com/legal/l/dpa">
                  Grundlage Drittlandübermittlung
                </a>
                : {""}EU-US Data Privacy Framework (DPF),
                Standardvertragsklauseln;
              </p>

              <p className="text">
                <span className="fw-bold">Weitere Informationen: </span>
                Wir sind gemeinsam mit LinkedIn Irland Unlimited Company für die
                Erhebung (jedoch nicht die weitere Verarbeitung) von Daten der
                Besucher, die zu Zwecken der Erstellung der „Page-Insights"
                (Statistiken) unserer LinkedIn-Profile verantwortlich. Zu diesen
                Daten gehören Informationen zu den Arten von Inhalten, die
                Nutzer sich ansehen oder mit denen sie interagieren, oder die
                von ihnen vorgenommenen Handlungen sowie Informationen über die
                von den Nutzern genutzten Geräte (z. B. IP-Adressen,
                Betriebssystem, Browsertyp, Spracheinstellungen, Cookie-Daten)
                und Angaben aus dem Profil der Nutzer, wie Berufsfunktion, Land,
                Branche, Hierarchieebene, Unternehmensgröße und
                Beschäftigungsstatus. Datenschutzinformationen zur Verarbeitung
                von Daten der Nutzer durch LinkedIn können den{" "}
                <a
                  className="link"
                  href="https://www.linkedin.com/legal/privacy-policy"
                >
                  Datenschutzhinweisen von LinkedIn{" "}
                </a>
                Datenschutzhinweisen von LinkedIn entnommen werden.
              </p>
              <p className="text">
                Wir haben mit LinkedIn Irland eine spezielle Vereinbarung
                abgeschlossen ("
                <a
                  className="link"
                  href="https://legal.linkedin.com/pages-joint-controller-addendum"
                >
                  Page Insights Joint Controller Addendum (the ‚Addendum‘)
                </a>
                "), in der insbesondere geregelt wird, welche
                Sicherheitsmaßnahmen LinkedIn beachten muss und in der LinkedIn
                sich bereit erklärt hat die Betroffenenrechte zu erfüllen (d. h.
                Nutzer können z. .B. Auskünfte oder Löschungsanfragen direkt an
                LinkedIn richten). Die Rechte der Nutzer (insbesondere auf
                Auskunft, Löschung, Widerspruch und Beschwerde bei zuständiger
                Aufsichtsbehörde), werden durch die Vereinbarungen mit LinkedIn
                nicht eingeschränkt. Die gemeinsame Verantwortlichkeit
                beschränkt sich auf die Erhebung der Daten durch und die
                Übermittlung an die Ireland Unlimited Company, ein Unternehmen
                mit Sitz in der EU. Die weitere Verarbeitung der Daten obliegt
                ausschließlich der Ireland Unlimited Company, was insbesondere
                die Übermittlung der Daten an die Muttergesellschaft LinkedIn
                Corporation in den USA betrifft.
              </p>
            </div>

            <div className="privacy-policy__textBlock">
              <h3 className="title-gradient subtitle ">
                Änderung und Aktualisierung der Datenschutz­erklärung
              </h3>
              <p className="text">
                Wir bitten Sie, sich regelmäßig über den Inhalt unserer
                Datenschutzerklärung zu informieren. Wir passen die
                Datenschutzerklärung an, sobald die Änderungen der von uns
                durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
                informieren Sie, sobald durch die Änderungen eine
                Mitwirkungshandlung Ihrerseits (z. B. Einwilligung) oder eine
                sonstige individuelle Benachrichtigung erforderlich wird.
              </p>
              <p className="text pt-20">
                Sofern wir in dieser Datenschutzerklärung Adressen und
                Kontaktinformationen von Unternehmen und Organisationen angeben,
                bitten wir zu beachten, dass die Adressen sich über die Zeit
                ändern können und bitten die Angaben vor Kontaktaufnahme zu
                prüfen.
              </p>
              <p className="text pt-20">Für uns zuständige Aufsichtsbehörde:</p>
              <p className="text pt-20">
                Der Hessische Beauftragte für Datenschutz und
                Informationsfreiheit
                <br />
                Postfach 3163
                <br /> 65021 Wiesbaden <br /> Telefon: 0611/1408 – 0 <br />
                Telefax: 0611/1408 – 611
              </p>
            </div>

            <div className="privacy-policy__textBlock">
              <h3 className="title-gradient subtitle ">
                Begriffs­definitionen
              </h3>
              <p className="text">
                In diesem Abschnitt erhalten Sie eine Übersicht über die in
                dieser Datenschutzerklärung verwendeten Begrifflichkeiten.
                Soweit die Begrifflichkeiten gesetzlich definiert sind, gelten
                deren gesetzliche Definitionen. Die nachfolgenden Erläuterungen
                sollen dagegen primär dem Verständnis dienen.
                <ul className="privacy-policy__list privacy-policy__list_nested pt-20">
                  <li>
                    <span className="fw-bold">Personenbezogene Daten:</span>{" "}
                    "Personenbezogene Daten" sind alle Informationen, die sich
                    auf eine identifizierte oder identifizierbare natürliche
                    Person (im Folgenden "betroffene Person") beziehen; als
                    identifizierbar wird eine natürliche Person angesehen, die
                    direkt oder indirekt, insbesondere mittels Zuordnung zu
                    einer Kennung wie einem Namen, zu einer Kennnummer, zu
                    Standortdaten, zu einer Online-Kennung (z. B. Cookie) oder
                    zu einem oder mehreren besonderen Merkmalen identifiziert
                    werden kann, die Ausdruck der physischen, physiologischen,
                    genetischen, psychischen, wirtschaftlichen, kulturellen oder
                    sozialen Identität dieser natürlichen Person sind.
                  </li>
                  <li>
                    <span className="fw-bold"> Verantwortlicher:</span> Als
                    "Verantwortlicher" wird die natürliche oder juristische
                    Person, Behörde, Einrichtung oder andere Stelle, die allein
                    oder gemeinsam mit anderen über die Zwecke und Mittel der
                    Verarbeitung von personenbezogenen Daten entscheidet,
                    bezeichnet.
                  </li>
                  <li>
                    <span className="fw-bold"> Verarbeitung:</span>{" "}
                    "Verarbeitung" ist jeder mit oder ohne Hilfe automatisierter
                    Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe
                    im Zusammenhang mit personenbezogenen Daten. Der Begriff
                    reicht weit und umfasst praktisch jeden Umgang mit Daten,
                    sei es das Erheben, das Auswerten, das Speichern, das
                    Übermitteln oder das Löschen.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
