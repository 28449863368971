import RectangeleMobile from "common/assets/images/rectangle-mobile-impressum.png";
import RectangeleImpressum from "common/assets/images/impressum/rectangle-impressum.png";
import { useTranslation } from "react-i18next";

import "./styles.scss";

function NetworkitRules() {
  const [t] = useTranslation("common");
  return (
    <div className="impressum">
      <img
        className="impressum__rectangle"
        src={RectangeleImpressum}
        alt="rectangle - impressum"
      />
      <img
        className="impressum__rectangle-mobile"
        src={RectangeleMobile}
        alt="rectangle mobile"
      />
      <div className="container">
        {/* -------------------- English version -------------------- */}
        <div className="impressum__contentWrapper">
          <div className="impressum__leftContent">
            <h1 className="title">Community Guidelines</h1>
            <br />
            <div className="impressum__name">
              <h3 className="title-gradient subtitle">
                Autoren
              </h3>
              <p className="text">{t("impressum.name")}</p>
            </div>
            <div className="impressum__name">
              <h3 className="title-gradient subtitle">
                Version vom
              </h3>
              <p className="text">06.11.2023</p>
            </div>
          </div>
          <div className="impressum__rightContent">
            <div className="impressum__textBlock">
              <h3 className="title-gradient subtitle">General Guidelines</h3>
              <p><strong></strong></p>
              <ol>
                  <li><strong><b>Respectful</b> Communication:</strong> Treat fellow community members with respect and courtesy. Avoid personal attacks, insults, or any form of harassment or bullying.</li>
                  <li><strong>Stay on Topic:</strong> Keep discussions relevant to the primary purpose of the chat group, which is to foster knowledge sharing and collaboration within the IT community. Discussions on other topics should take place in the off-topic chat.</li>
                  <li><strong>No Advertising or Spam:</strong> Refrain from posting promotional content, unsolicited advertisements, or spam messages.</li>
                  <li><strong>Protect Privacy:</strong> Respect the privacy of others by avoiding sharing personal information without their consent.</li>
                  <li><strong>Adhere to German Laws:</strong> Comply with all applicable German laws and regulations regarding online communication and conduct.</li>
              </ol>
            </div>

            <div className="impressum__textBlock">
              <h3 className="title-gradient subtitle">Content Sharing and Discussion</h3>
              <ol>
                  <li><strong>Quality Contributions:</strong> Share valuable information, insights, and resources that benefit the IT community.</li>
                  <li><strong>Accurate Information:</strong> Ensure the accuracy and credibility of the information you share.</li>
                  <li><strong>Proper Attribution:</strong> Give credit to the original sources of information whenever applicable.</li>
                  <li><strong>Avoid Duplication:</strong> Refrain from posting repetitive or redundant content that has already been shared.</li>
                  <li><strong>Use Clear Language:</strong> Employ clear and concise language to facilitate easy understanding.</li>
                  <li><strong>Posting vacancies:</strong> Due to the high volume of spam from scammers, as well as legal regulations, posting any vacancies must be coordinated with the community administrators.</li>
              </ol>
            </div>

            <div className="impressum__textBlock">
              <h3 className="title-gradient subtitle">Moderation and Enforcement</h3>
              <ol>
                  <li><strong>Moderator Authority:</strong> Respect the decisions of the chat group moderators, who are responsible for maintaining order and enforcing the rules.</li>
                  <li><strong>Report Misconduct:</strong> If you witness any violation of these rules, report it promptly to the moderators.</li>
                  <li><strong>Compliance with Warnings:</strong> Comply with any warnings or temporary bans issued by the moderators.</li>
                  <li><strong>Appeals:</strong> If you disagree with a moderator's decision, you may appeal the decision privately to the group administrators.</li>
              </ol>
            </div>

            <div className="impressum__textBlock">
              <h3 className="title-gradient subtitle">Additional Considerations</h3>
              <ol>
                  <li><strong>Language Preferences:</strong> Use Russian or German as the primary language for communication within the chat group.</li>
                  <li><strong>Cultural Sensitivity:</strong> Be mindful of cultural sensitivities and avoid making discriminatory or offensive remarks.</li>
                  <li><strong>Professionalism:</strong> Maintain a professional in your interactions with fellow community members.</li>
                  <li><strong>Continuous Improvement:</strong> Welcome feedback and suggestions for enhancing the chat group experience.</li>
              </ol>
            </div>
          </div>
        </div>
        {/* -------------------- German version -------------------- */}
        <div className="impressum__contentWrapper">
          <div className="impressum__leftContent">
            <h1 className="title">Leitlinien der Gruppe</h1>
          </div>
          <div className="impressum__rightContent">
            <div className="impressum__textBlock">
              <h3 className="title-gradient subtitle">Allgemeine Leitlinien</h3>
              <p><strong></strong></p>
              <ol>
                  <li><strong>Respektvolle Kommunikation:</strong> Behandeln Sie andere Mitglieder der Gemeinschaft mit Respekt und Höflichkeit. Vermeiden Sie persönliche Angriffe, Beleidigungen oder jegliche Form von Belästigung oder Mobbing.</li>
                  <li><strong>Bleiben Sie beim Thema:</strong> Halten Sie die Diskussionen auf den Hauptzweck der Chat-Gruppe beschränkt, der darin besteht, den Wissensaustausch und die Zusammenarbeit innerhalb der IT-Community zu fördern. Diskussionen über andere Themen sollten im Off-Topic-Chat geführt werden.</li>
                  <li><strong>Keine Werbung oder Spam:</strong> Unterlassen Sie das Posten von Werbeinhalten, unerwünschter Werbung oder Spam-Nachrichten.</li>
                  <li><strong>Schutz der Privatsphäre:</strong> Respektieren Sie die Privatsphäre anderer, indem Sie keine persönlichen Informationen ohne deren Zustimmung weitergeben.</li>
                  <li><strong>Halten Sie sich an deutsche Gesetze:</strong> Halten Sie alle geltenden deutschen Gesetze und Vorschriften zur Online-Kommunikation und zum Online-Verhalten ein.</li>
              </ol>
            </div>

            <div className="impressum__textBlock">
              <h3 className="title-gradient subtitle">Austausch von Inhalten und Diskussionen</h3>
              <ol>
                  <li><strong>Hochwertige Beiträge:</strong> Teilen Sie wertvolle Informationen, Erkenntnisse und Ressourcen, die der IT-Gemeinschaft zugute kommen.</li>
                  <li><strong>Genaue Informationen:</strong> Achten Sie auf die Richtigkeit und Glaubwürdigkeit der Informationen, die Sie weitergeben.</li>
                  <li><strong>Korrekte Namensnennung:</strong> Geben Sie die Originalquellen der Informationen an, wann immer dies möglich ist.</li>
                  <li><strong>Vermeiden Sie Wiederholungen:</strong> Unterlassen Sie es, sich wiederholende oder redundante Inhalte zu veröffentlichen, die bereits geteilt worden sind.</li>
                  <li><strong>Klare Sprache verwenden:</strong> Verwenden Sie eine klare und prägnante Sprache, um ein einfaches Verständnis zu ermöglichen.</li>
                  <li><strong>Stellenangebote ausschreiben:</strong> Aufgrund des hohen Spamaufkommens von Betrügern und der gesetzlichen Bestimmungen muss die Veröffentlichung von Stellenangeboten mit den Community-Administratoren abgestimmt werden.</li>
              </ol>
            </div>

            <div className="impressum__textBlock">
              <h3 className="title-gradient subtitle">Moderation und Durchsetzung</h3>
              <ol>
                  <li><strong>Autorität der Moderatoren:</strong> Respektieren Sie die Entscheidungen der Moderatoren der Chatgruppe, die für die Aufrechterhaltung der Ordnung und die Durchsetzung der Regeln verantwortlich sind.</li>
                  <li><strong>Fehlverhalten melden:</strong> Wenn Sie Zeuge eines Verstoßes gegen diese Regeln werden, melden Sie ihn umgehend den Moderatoren.</li>
                  <li><strong>Befolgung von Verwarnungen:</strong> Befolgen Sie alle von den Moderatoren ausgesprochenen Verwarnungen oder vorübergehenden Verbote.</li>
                  <li><strong>Einsprüche:</strong> Wenn Sie mit der Entscheidung eines Moderators nicht einverstanden sind, können Sie die Entscheidung privat bei den Gruppenadministratoren anfechten.</li>
              </ol>
            </div>

            <div className="impressum__textBlock">
              <h3 className="title-gradient subtitle">Zusätzliche Aspekte</h3>
              <ol>
                  <li><strong>Bevorzugte Sprachen: </strong> Verwenden Sie Russisch oder Deutsch als Hauptsprache für die Kommunikation innerhalb der Chat-Gruppe.</li>
                  <li><strong>Kulturelle Empfindsamkeit:</strong> Achten Sie auf kulturelle Empfindlichkeiten und vermeiden Sie diskriminierende oder beleidigende Äußerungen.</li>
                  <li><strong>Professionalität:</strong> Bleiben Sie professionell im Umgang mit anderen Community-Mitgliedern.</li>
                  <li><strong>Kontinuierliche Verbesserung</strong> Wir freuen uns über Feedback und Vorschläge zur Verbesserung der Chatgruppenerfahrung.</li>
              </ol>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default NetworkitRules;
