import PreviewImageProjects from "common/assets/images/commonprojects/previewprojects.png";
import { useTranslation, TFunction } from "react-i18next";
import UserAvatarImg from "common/assets/images/commonprojects/user-avatar.png";
import CardProject from "common/components/Cards/CardProject";
import EdgarAvatar from "common/assets/images/commonprojects/edgar.jpg";
import BogdanAvatar from "common/assets/images/commonprojects/bogdan.jpg";
import AlexAvatar from "common/assets/images/commonprojects/alex.jpg";
import MitmatePreview from "common/assets/images/commonprojects/mitmatepreview.jpg";
import FinelboPreview from "common/assets/images/commonprojects/finelbopreview.png";
import Rectangle from "common/assets/images/rectangle-common-mobile.png";

import "./styles.scss";

const projects = (t: TFunction) => [
 
  {
    previewImg: FinelboPreview,
    title: "Finelbo",
    text: t("projects.textSecond"),
    link: "https://apps.apple.com/de/app/finelbo/id1609473969",
    owners: [
      {
        name: "Bogdan",
        descr: t("projects.descrSecond"),
        avatar: BogdanAvatar,
        link: "https://www.linkedin.com/in/bogdan-lashkov/"
      },
      {
        name: "Alex",
        descr: t("projects.descrSecond"),
        avatar: AlexAvatar,
        link: "https://www.linkedin.com/in/aleksandr-kil-a5b89021b/"
        
      },
      {
        name: "Edgar",
        descr: t("projects.descrSecond"),
        avatar: EdgarAvatar,
        link: "https://www.linkedin.com/in/edgar-linde-a55775177/"
      }
    ],
  },

];

function CommonProjects() {
  const [t] = useTranslation("common");
  return (
    <div className="commonProjects">
      <img src={Rectangle} className="rectangle" alt="rectangle" />
      <div className="container">
        <h1 className="title">{t("commonProjects.title")}</h1>
        <div className="commonProjects__cardsWrapper">
          {projects(t).map(({ previewImg, title, text, link, owners}) => (
            <CardProject
              key={title}
              previewImg={previewImg}
              title={title}
              text={text}
              link={link}
              users={owners}
            />
          ))}
        </div>
      </div>
    </div>
  );
}


export default CommonProjects;
