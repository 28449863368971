import InstagramIcon from "common/assets/icons/footer/instagram.svg";
import DiscordIcon from "common/assets/icons/footer/discord.svg";
import LinkedinIcon from "common/assets/icons/footer/linkedin.svg";

import "./styles.scss";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

function Footer() {
  const { t } = useTranslation("common");

  return (
    <div className="footer">
      <div className="container">
        <div className="footer__nav">
          <NavLink to="/" className="text">
            {t("navbar.main")}
          </NavLink>
          {/* <NavLink to="meetups" className="text">
            {t("navbar.meetups")}
          </NavLink> */}
          <a
            href="https://www.eventbrite.com/e/network-itio-meet-up-tickets-697599477817?aff=ebdsoporgprofile"
            className="text"
          >
            {t("navbar.meetups")}
          </a>
          <NavLink to="map" className="text">
            {t("navbar.map")}
          </NavLink>
        </div>
        <div className="footer__nav">
          <NavLink to="common-projects" className="text">
            {t("navbar.projects")}
          </NavLink>
          <a
            href="https://innovaforge.de/jobs/"
            className="text"
          >
            {t("navbar.vacancies")}
          </a>
          {/* <NavLink to="vacancies" className="text">
            {t("navbar.vacancies")}
          </NavLink> */}
          <NavLink to="contacts" className="text">
            {t("navbar.contacts")}
          </NavLink>
        </div>
        <div className="footer__nav">
          <NavLink to="impressum" className="text">
            {t("navbar.impressum")}
          </NavLink>
          <NavLink to="privacy-policy" className="text">
            {t("navbar.privacy-policy")}
          </NavLink>
        </div>
        <div className="footer__rightContent">
          <div className="footer__iconsWrapper">
            <a
              className="footer__icon"
              href="https://discord.gg/rnHhAaYVS5"
              target="_blank"
            >
              <img src={DiscordIcon} alt="discord" />
            </a>
            <a
              className="footer__icon"
              href="https://www.instagram.com/network_it.io/"
              target="_blank"
            >
              <img src={InstagramIcon} alt="instagram" />
            </a>
            <a
              className="footer__icon"
              href="https://de.linkedin.com/company/innovaforge"
              target="_blank"
            >
              <img src={LinkedinIcon} alt="linkedin" />
            </a>
          </div>
          <a className="text" href="innovaforge.de">
            Innovaforge IT Consulting & Softwareentwicklung GmbH
          </a>
          <span></span>
          networkit-it.io verwendet keine Cookies
        </div>
      </div>
    </div>
  );
}

export default Footer;
