import InstagramIcon from "common/assets/images/contacts/instagram.svg";
import TelegramIcon from "common/assets/images/contacts/telegram.svg";
import LinkedinIcon from "common/assets/images/contacts/linkedin.svg";
import "./styles.scss";

type Props = {
  avatarImg: string;
  title: string;
  text: string;
  instagramUrl: string;
  telegramUrl: string;
  linkedinUrl: string;
};
function CardContacts({ avatarImg, title, text, instagramUrl, telegramUrl, linkedinUrl }: Props) {
  return (
    <div className="cardContacts">
      <img className="cardContacts__avatarImg" src={avatarImg} alt="avatar" />
      <h3 className="subtitle">{title}</h3>
      <p className="cardContacts__text">{text}</p>
      <div className="cardContacts__socialIconsWrapper">
      <a className="cardContacts__socialIcon" href={instagramUrl} target="_blank"> 
        <img className="socialIcon" src={InstagramIcon} alt="Instagram" />
      </a>
      <a className="cardContacts__socialIcon" href={linkedinUrl} target="_blank"> 
        <img className="socialIcon" src={LinkedinIcon} alt="Linkedin" />
      </a>
      <a className="cardContacts__socialIcon" href={telegramUrl} target="_blank"> 
        <img className="socialIcon" src={TelegramIcon} alt="Telegram" />
      </a>
      </div>
    </div>
  );
}

export default CardContacts;
