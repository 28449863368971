import { useState } from "react";
import { NavLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import LogoImg from "common/assets/logo/logo.svg";
import SideDrawer from "common/components/SideDrawer/SideDrawer";
import { ReactComponent as MenuIcon } from "common/assets/icons/menu-icon.svg";
/* import Select, { SelectChangeEvent } from "@mui/material/Select"; */
/* import MenuItem from "@mui/material/MenuItem"; */
import { useTranslation, TFunction } from "react-i18next";
/* import RuFlag from "common/assets/icons/flags/russia.png";
import GeFlag from "common/assets/icons/flags/germany.png";
import EnFlag from "common/assets/icons/flags/united-kingdom.png"; */

import "./styles.scss";

const navItemList = (t: TFunction) => [
  {
    text: t("navbar.main"),
    to: "/",
  },
  {
    text: t("navbar.meetups"),
    to: "https://www.eventbrite.com/e/network-itio-meet-up-tickets-697599477817?aff=ebdsoporgprofile",
  },
  // {
  //   text: t("navbar.map"),
  //   to: "map",
  // },
  {
    text: t("navbar.projects"),
    to: "common-projects",
  },
  {
    text: t("navbar.vacancies"),
    to: "https://innovaforge.de/jobs/",
  },
  {
    text: t("navbar.contacts"),
    to: "contacts",
  },
];

/* const langMenuItems = [{ name: "RU" }, { name: "DE" }]; */

function Header() {
  const [t] = useTranslation("common");
  const [open, setOpen] = useState<boolean>(false);
  /*   const [_language, setLanguage] = useState(
    localStorage.getItem("lang") || "RU",
  ); */

  const openDrawerHandler = () => {
    setOpen(true);
  };

  const closeDrawerHandler = () => {
    setOpen(false);
  };

  /*   const onLanguageChange = (e: SelectChangeEvent) => {
    setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
    window.location.reload();
  }; */

  return (
    <>
      {/* <SupportUkraineBanner /> */}
      <header className="header container">
        <div className="header__leftContent">
          <NavLink to="/">
            <img className="header__logo" src={LogoImg} alt="logo" />
          </NavLink>

          {/* Disable language switcher for now */}
          {/*           <Select
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSelect-select": {
                display: "flex",
                textTransform: "uppercase",
              },
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label="Language"
            onChange={onLanguageChange}
          >
            {langMenuItems.map(({ name }) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select> */}
        </div>
        <div className="header__nav">
          {navItemList(t).map(({ text, to }) => {
            if (to.includes("eventbrite") || to.includes("jobs")) {
              return (
                <a
                  key={to + Math.random()}
                  className="header__navItem"
                  href={to}
                >
                  {text}
                </a>
              );
            }
            return (
              <NavLink
                className={({ isActive }) =>
                  isActive ? "header__navItem active" : "header__navItem"
                }
                to={to}
                key={text}
                end
              >
                {text}
              </NavLink>
            );
          })}
        </div>
        <IconButton
          className="header__menuBtn"
          color="inherit"
          aria-label="open drawer"
          onClick={openDrawerHandler}
          edge="start"
        >
          <MenuIcon className="header__menuIcon" />
        </IconButton>
      </header>
      <SideDrawer open={open} onClose={closeDrawerHandler}>
        {navItemList(t).map(({ text, to }) => {
          if (to.includes("eventbrite") || to.includes("jobs")) {
            return (
              <a key={to + Math.random()} className="header__navItem" href={to}>
                {text}
              </a>
            );
          }

          return (
            <NavLink
              onClick={closeDrawerHandler}
              className={({ isActive }) =>
                isActive ? "header__navItem active" : "header__navItem"
              }
              to={to}
              key={to}
              end
            >
              {text}
            </NavLink>
          );
        })}
      </SideDrawer>
    </>
  );
}

export default Header;
