import TelegramImg from "common/assets/images/telegram-image.png";
import TelegramIcon from "common/assets/icons/telegram.svg";
import DiscordIcon from "common/assets/images/opportunities/discordIcon.svg";
import { useTranslation } from "react-i18next";
import CustomButton from "common/components/Buttons/CustomButton/CustomButton";
import Rectangle from "common/assets/images/rectangle-telegram-block.png";
import TelegramRectangleMobile from "common/assets/images/telegram-rectangle-mobile.png";
import DiscordRectangleMobile from "common/assets/images/discord-rectangle-mobile.png";
import getCurrentLang from "utils/getCurrentLang";

import "./styles.scss";

function ConnectToTelegram() {
  const [t] = useTranslation("common");

  const redirectClickHandler = () => {
    window.location.href = t("communityLink");
  };

  return (
    <div className="connectToTelegram">
      <img
        className="connectToTelegram__rectangle"
        src={Rectangle}
        alt="rectangle"
      />
      {getCurrentLang() == 'ru' ?
      <img
        className="connectToTelegram__rectangleMobile"
        src={TelegramRectangleMobile}
        alt="rectangle"
      /> : 
      <img
        className="connectToTelegram__rectangleMobile"
        src={DiscordRectangleMobile}
        alt="rectangle"
      /> }
      <div className="container">
        <div className="connectToTelegram__left">
          <h1 className="title">
            {t("communities.connectToTelegramTitle")}
            <span className="title-gradient">{t("communities.connectToTelegramWord")}</span>
          </h1>
          <p className="text">{t("communities.connectToTelegramText")}</p>
          <CustomButton
            text={t("communities.goToTelegramText")}
            onClick={redirectClickHandler}
          />
        </div>
        {getCurrentLang() == 'ru' ?
        <img src={TelegramIcon} className="telegramImg" alt="Telegram" width="700"/>
        : 
        <img src={DiscordIcon} className="telegramImg" alt="Discord" width="700" />
      }
       
      </div>
    </div>
  );
}

export default ConnectToTelegram;
